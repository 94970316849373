import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Head from '../components/Head';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Form from '../components/Form';

import GlobalStyle from '../GlobalStyle';
import { COLORS } from '../theme';

export default ({ data }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Head pageTitle="Impressum" pageUrl="/impressum" />
      <Header absolute />

      <Container>
        <P>
          <Strong>SchlossHOTEL Gedern</Strong>
          <br />
          Schlossberg 5<br />
          63688 Gedern
          <br />
          Telefon: <a href="tel:+49604596150">+49 6045 9615-0</a>
          <br />
          Telefax: <a href="fax:+496045961548">+49 6045 9615-48</a>
          <br />
          E-Mail:{' '}
          <a href="mailto:info@schlosshotel-gedern.de">
            info@schlosshotel-gedern.de
          </a>
        </P>

        <P>
          <Strong>Inhaber</Strong>: Hubertus Schultz
          <br />
          <Strong>Zuständige Aufsichtsbehörde</Strong>: Gewerbeamt der Stadt
          Gedern
        </P>

        <P>
          <Strong>
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz
          </Strong>
          :<br />
          DE 20 650 3411
        </P>

        <P>
          <Strong>Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV</Strong>:
          Ute Schultz
        </P>

        <P>
          <Strong>Haftungshinweis</Strong>: Trotz sorgfältiger inhaltlicher
          Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links.
          Für den Inhalt der verlinkten Seiten sind ausschließlich deren
          Betreiber verantwortlich.
        </P>
      </Container>

      <Footer
        image={data.prismicHomepage.data.outro}
        address={data.prismicHomepage.data.address.html}
      />
      <GlobalStyle />
    </div>
  );
};

const Container = styled.div`
  margin: 0 auto 80px;
  padding: 0 2rem;
  max-width: 650px;

  a {
    color: inherit;
  }
`;

const P = styled.p`
  margin-bottom: 2rem;
  color: ${COLORS.TEXT_PRIMARY};
`;

const Strong = styled.strong`
  font-weight: 500;
`;

export const pageQuery = graphql`
  {
    prismicHomepage {
      data {
        address {
          html
        }
        outro {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
